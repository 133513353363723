"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.needDrawInterpolationPoint = exports.computeInterpolationStep = exports.needDrawInterpolation = exports.computeMovements = exports.isInImageRange = void 0;
var constants_1 = require("./constants");
var sign = Math.sign, abs = Math.abs, max = Math.max;
function isInImageRange(inRangeConfig) {
    var x = inRangeConfig.x, y = inRangeConfig.y, minX = inRangeConfig.minX, maxX = inRangeConfig.maxX, minY = inRangeConfig.minY, maxY = inRangeConfig.maxY;
    return x >= minX && x <= maxX && y >= minY && y <= maxY;
}
exports.isInImageRange = isInImageRange;
function computeMovements(movements) {
    var movementX = movements.movementX, movementY = movements.movementY;
    var unsignedMovementX = abs(movementX);
    var unsignedMovementY = abs(movementY);
    var maxMovement = max(unsignedMovementX, unsignedMovementY);
    return { unsignedMovementX: unsignedMovementX, unsignedMovementY: unsignedMovementY, maxMovement: maxMovement };
}
exports.computeMovements = computeMovements;
function needDrawInterpolation(maxMovement, radius) {
    return radius > constants_1.DRAW_INTERPOLATION_RADIUS_THRESHOLD && maxMovement > radius / constants_1.DRAW_INTERPOLATION_STEP_BASE;
}
exports.needDrawInterpolation = needDrawInterpolation;
function computeInterpolationStep(interpolationConfig) {
    var drawingConfig = interpolationConfig.drawingConfig, unsignedMovementX = interpolationConfig.unsignedMovementX, unsignedMovementY = interpolationConfig.unsignedMovementY, maxMovement = interpolationConfig.maxMovement;
    var movementX = drawingConfig.movementX, movementY = drawingConfig.movementY, stepBase = drawingConfig.stepBase;
    var rawStepX = computePivotRawStep(movementX, stepBase);
    var rawStepY = computePivotRawStep(movementY, stepBase);
    var movementXIsMaximum = isMaxMovement(unsignedMovementX, maxMovement);
    var stepX = computePivotStep(movementXIsMaximum, rawStepX, unsignedMovementX, unsignedMovementY);
    var stepY = computePivotStep(!movementXIsMaximum, rawStepY, unsignedMovementY, unsignedMovementX);
    return { stepX: stepX, stepY: stepY };
}
exports.computeInterpolationStep = computeInterpolationStep;
function computePivotRawStep(pivotMovement, stepBase) {
    return -sign(pivotMovement) * stepBase;
}
function isMaxMovement(pivotMovement, maxMovement) {
    return pivotMovement === maxMovement;
}
function computePivotStep(isMaxMovement, rawStepOfPivot, unsignedPivotMovement, unsignedCrossedMovement) {
    return isMaxMovement ? rawStepOfPivot : (unsignedPivotMovement / unsignedCrossedMovement) * rawStepOfPivot;
}
function needDrawInterpolationPoint(movement, moved, step) {
    return movement - moved > step;
}
exports.needDrawInterpolationPoint = needDrawInterpolationPoint;
