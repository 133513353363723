"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_SCALE_RATIO = exports.MIN_SCALE_RATIO = exports.SCALE_STEP = exports.ZOOM_OUT_COEFFICIENT = exports.ZOOM_IN_COEFFICIENT = exports.ACTION_THROTTLE_TIME = exports.ONE_TURN_DEGREES = exports.ZERO_DEGREES = exports.ERASE_POINT_OUTER_COLOR = exports.ERASE_POINT_INNER_COLOR = exports.REPAIR_POINT_OUTER_COLOR = exports.REPAIR_POINT_INNER_COLOR = exports.GRADIENT_END_OFFSET = exports.GRADIENT_BEGIN_OFFSET = exports.GRADIENT_INNER_RADIUS = exports.DRAW_INTERPOLATION_RADIUS_THRESHOLD = exports.DRAW_INTERPOLATION_STEP_BASE = exports.GLOBAL_COMPOSITE_OPERATION_DESTINATION_OUT = exports.GLOBAL_COMPOSITE_OPERATION_DESTINATION_IN = exports.GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER = exports.DRAWING_STEP_BASE = exports.DRAWING_STEP_BASE_BASE = exports.UPDATE_BOARDRECT_DEBOUNCE_TIME = exports.DEFAULT_MASK_COLOR = exports.INITIAL_IMAGE_BORDER_WIDTH = exports.IMAGE_BORDER_STYLE = exports.DEFAULT_IMAGE_SMOOTH_CHOICE = exports.HIDDEN_BOARD_MAX_SIZE = exports.HIDDEN_BOARD_GAP_SIZE = exports.INITIAL_GAP_SIZE = exports.INITIAL_TRANSFORM_CONFIG = exports.INITIAL_SCALE_RATIO = exports.HARDNESS_ZOOM_TO_SLIDER_RATIO = exports.HARDNESS_SLIDER_MAX = exports.HARDNESS_SLIDER_STEP = exports.HARDNESS_SLIDER_MIN = exports.MIN_RADIUS = exports.RADIUS_SLIDER_MAX = exports.RADIUS_SLIDER_STEP = exports.RADIUS_SLIDER_MIN = exports.RADIUS_TO_BRUSH_SIZE_RATIO = exports.INITIAL_HARDNESS = exports.INITIAL_RADIUS = exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType["Mouseover"] = "mouseover";
    EventType["Mouseenter"] = "mouseenter";
    EventType["Mouseout"] = "mouseout";
    EventType["Mouseleave"] = "mouseleave";
    EventType["Mouseup"] = "mouseup";
    EventType["Mousemove"] = "mousemove";
    EventType["MouseDown"] = "mousedown";
    EventType["DblClick"] = "dblclick";
    EventType["Click"] = "click";
    EventType["ContextMenu"] = "contextmenu";
    EventType["KeyDown"] = "keydown";
    EventType["Keyup"] = "keyup";
    EventType["Keypress"] = "keypress";
    EventType["Scroll"] = "scroll";
    EventType["Resize"] = "resize";
    EventType["Wheel"] = "wheel";
    EventType["UndoRedoStateChanged"] = "undoRedoStateChanged";
})(EventType = exports.EventType || (exports.EventType = {}));
exports.INITIAL_RADIUS = 12;
exports.INITIAL_HARDNESS = 0.5;
exports.RADIUS_TO_BRUSH_SIZE_RATIO = 4;
exports.RADIUS_SLIDER_MIN = 0.25;
exports.RADIUS_SLIDER_STEP = 0.25;
exports.RADIUS_SLIDER_MAX = 25;
exports.MIN_RADIUS = 0.5;
exports.HARDNESS_SLIDER_MIN = 0;
exports.HARDNESS_SLIDER_STEP = 0.01;
exports.HARDNESS_SLIDER_MAX = 1;
exports.HARDNESS_ZOOM_TO_SLIDER_RATIO = 100;
exports.INITIAL_SCALE_RATIO = 1;
exports.INITIAL_TRANSFORM_CONFIG = {
    scaleRatio: exports.INITIAL_SCALE_RATIO,
    positionRange: {
        minX: 0,
        maxX: 0,
        minY: 0,
        maxY: 0,
    },
};
exports.INITIAL_GAP_SIZE = {
    horizontal: 40,
    vertical: 80,
};
exports.HIDDEN_BOARD_GAP_SIZE = {
    horizontal: 0,
    vertical: 0,
};
exports.HIDDEN_BOARD_MAX_SIZE = {
    width: 2000,
    height: 2000,
};
exports.DEFAULT_IMAGE_SMOOTH_CHOICE = false;
exports.IMAGE_BORDER_STYLE = '#000000';
exports.INITIAL_IMAGE_BORDER_WIDTH = 1;
exports.DEFAULT_MASK_COLOR = [0.47, 0.42, 0.9, 0.5];
exports.UPDATE_BOARDRECT_DEBOUNCE_TIME = 100;
exports.DRAWING_STEP_BASE_BASE = 20;
exports.DRAWING_STEP_BASE = 3.5;
exports.GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER = 'source-over';
exports.GLOBAL_COMPOSITE_OPERATION_DESTINATION_IN = 'destination-in';
exports.GLOBAL_COMPOSITE_OPERATION_DESTINATION_OUT = 'destination-out';
exports.DRAW_INTERPOLATION_STEP_BASE = 2.5;
exports.DRAW_INTERPOLATION_RADIUS_THRESHOLD = 1;
exports.GRADIENT_INNER_RADIUS = 0;
exports.GRADIENT_BEGIN_OFFSET = 0;
exports.GRADIENT_END_OFFSET = 1;
exports.REPAIR_POINT_INNER_COLOR = '#875EFF';
exports.REPAIR_POINT_OUTER_COLOR = '#875EFF';
exports.ERASE_POINT_INNER_COLOR = '#5A5A5A';
exports.ERASE_POINT_OUTER_COLOR = '#5A5A5A';
exports.ZERO_DEGREES = 0;
exports.ONE_TURN_DEGREES = Math.PI * 2;
exports.ACTION_THROTTLE_TIME = 300;
exports.ZOOM_IN_COEFFICIENT = 1;
exports.ZOOM_OUT_COEFFICIENT = -1;
exports.SCALE_STEP = 0.04;
exports.MIN_SCALE_RATIO = 0.15;
exports.MAX_SCALE_RATIO = 10;
