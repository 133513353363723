"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeScaledImageSize = exports.computeNewTransformConfigByScaleRatio = exports.computeStep = exports.computeRealRadius = exports.computeStepBase = exports.getRawDistance = exports.fixed = void 0;
var constants_1 = require("./constants");
var sqrt = Math.sqrt, max = Math.max;
function fixed(num) {
    return num | 0;
}
exports.fixed = fixed;
function getRawDistance(xDistance, yDistance) {
    return sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
}
exports.getRawDistance = getRawDistance;
function computeStepBase(radius) {
    return radius / constants_1.DRAWING_STEP_BASE_BASE;
}
exports.computeStepBase = computeStepBase;
function computeRealRadius(rawRadius, scaleRatio) {
    return max(constants_1.MIN_RADIUS, rawRadius) / scaleRatio;
}
exports.computeRealRadius = computeRealRadius;
function computeStep(radius) {
    return radius / constants_1.DRAWING_STEP_BASE;
}
exports.computeStep = computeStep;
function computeNewTransformConfigByScaleRatio(transformConfig, pictureSize, scaleRatio) {
    var _a = transformConfig.positionRange, minX = _a.minX, minY = _a.minY;
    var width = pictureSize.width, height = pictureSize.height;
    var maxX = minX + width * scaleRatio;
    var maxY = minY + height * scaleRatio;
    return { positionRange: { minX: minX, maxX: maxX, minY: minY, maxY: maxY }, scaleRatio: scaleRatio };
}
exports.computeNewTransformConfigByScaleRatio = computeNewTransformConfigByScaleRatio;
function computeScaledImageSize(imageSize, scaleRatio) {
    return {
        width: imageSize.width * scaleRatio,
        height: imageSize.height * scaleRatio,
    };
}
exports.computeScaledImageSize = computeScaledImageSize;
