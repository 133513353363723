"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderCoverBgImageInCanvas = exports.computeCoverCropRect = exports.computeCropCropRect = exports.isInResultBoard = exports.drawBrushPoint = exports.executeMattingDrawing = void 0;
var constants_1 = require("./constants");
var dom_helper_1 = require("./dom-helper");
var drawing_compute_1 = require("./drawing-compute");
var util_1 = require("./util");
var interpolationCtx = dom_helper_1.createContext2D();
function executeMattingDrawing(config) {
    var radius = config.radius;
    var _a = drawing_compute_1.computeMovements(config), maxMovement = _a.maxMovement, unsignedMovementX = _a.unsignedMovementX, unsignedMovementY = _a.unsignedMovementY;
    if (drawing_compute_1.needDrawInterpolation(maxMovement, radius)) {
        renderMattingInterpolation({ drawingConfig: config, unsignedMovementX: unsignedMovementX, unsignedMovementY: unsignedMovementY, maxMovement: maxMovement });
    }
    else {
        drawMattingPoint(config);
    }
    drawResultArea(config);
}
exports.executeMattingDrawing = executeMattingDrawing;
function renderMattingInterpolation(interpolationConfig) {
    var drawingConfig = interpolationConfig.drawingConfig, maxMovement = interpolationConfig.maxMovement;
    var step = drawingConfig.step, stepBase = drawingConfig.stepBase, drawingCtx = drawingConfig.drawingCtx, radius = drawingConfig.radius, hardness = drawingConfig.hardness;
    var x = drawingConfig.x, y = drawingConfig.y;
    var _a = drawing_compute_1.computeInterpolationStep(interpolationConfig), stepX = _a.stepX, stepY = _a.stepY;
    resetInterpolationCtx(drawingCtx);
    for (var movement = 0, moved = movement; movement < maxMovement; movement += stepBase, x += stepX, y += stepY) {
        if (drawing_compute_1.needDrawInterpolationPoint(movement, moved, step)) {
            moved = movement;
            drawBrushPoint({ ctx: interpolationCtx, x: util_1.fixed(x), y: util_1.fixed(y), radius: radius, hardness: hardness });
        }
    }
    drawMattingInterpolationTrack(drawingConfig);
}
function drawMattingInterpolationTrack(drawingConfig) {
    var isErasing = drawingConfig.isErasing, hiddenCtx = drawingConfig.hiddenCtx, drawingCtx = drawingConfig.drawingCtx;
    if (isErasing) {
        hiddenCtx.value.drawImage(interpolationCtx.canvas, 0, 0);
    }
    else {
        drawMattingTrack(drawingConfig, function () {
            drawingCtx.drawImage(interpolationCtx.canvas, 0, 0);
        });
    }
}
function resetInterpolationCtx(drawingCtx) {
    var _a = drawingCtx.canvas, width = _a.width, height = _a.height;
    interpolationCtx.canvas.width = width;
    interpolationCtx.canvas.height = height;
    interpolationCtx.clearRect(0, 0, width, height);
}
function drawMattingPoint(drawingConfig) {
    var isErasing = drawingConfig.isErasing, hiddenCtx = drawingConfig.hiddenCtx, drawingCtx = drawingConfig.drawingCtx;
    var x = drawingConfig.x, y = drawingConfig.y, radius = drawingConfig.radius, hardness = drawingConfig.hardness;
    if (isErasing) {
        drawBrushPoint({ ctx: hiddenCtx.value, x: x, y: y, radius: radius, hardness: hardness });
    }
    else {
        drawMattingTrack(drawingConfig, function () {
            drawBrushPoint({ ctx: drawingCtx, x: x, y: y, radius: radius, hardness: hardness });
        });
    }
}
function drawMattingTrack(drawingConfig, drawingCallback) {
    var hiddenCtx = drawingConfig.hiddenCtx, drawingCtx = drawingConfig.drawingCtx, mattingSource = drawingConfig.mattingSource;
    drawingCtx.globalCompositeOperation = constants_1.GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER;
    drawingCtx.drawImage(mattingSource, 0, 0, drawingCtx.canvas.width, drawingCtx.canvas.height);
    drawingCtx.globalCompositeOperation = constants_1.GLOBAL_COMPOSITE_OPERATION_DESTINATION_IN;
    drawingCallback();
    hiddenCtx.value.drawImage(drawingCtx.canvas, 0, 0);
}
function drawBrushPoint(drawingConfig) {
    var ctx = drawingConfig.ctx, x = drawingConfig.x, y = drawingConfig.y, radius = drawingConfig.radius, hardness = drawingConfig.hardness;
    var _a = drawingConfig.innerColor, innerColor = _a === void 0 ? constants_1.REPAIR_POINT_INNER_COLOR : _a, _b = drawingConfig.outerColor, outerColor = _b === void 0 ? constants_1.REPAIR_POINT_OUTER_COLOR : _b;
    ctx.beginPath();
    var gradient = ctx.createRadialGradient(x, y, constants_1.GRADIENT_INNER_RADIUS, x, y, radius);
    gradient.addColorStop(constants_1.GRADIENT_BEGIN_OFFSET, innerColor);
    gradient.addColorStop(hardness, innerColor);
    gradient.addColorStop(constants_1.GRADIENT_END_OFFSET, outerColor);
    ctx.fillStyle = gradient;
    ctx.arc(x, y, radius, constants_1.ZERO_DEGREES, constants_1.ONE_TURN_DEGREES);
    ctx.fill();
}
exports.drawBrushPoint = drawBrushPoint;
function drawResultArea(drawingConfig) {
    var ctx = drawingConfig.ctx, hiddenCtx = drawingConfig.hiddenCtx, positionRange = drawingConfig.positionRange, scaleRatio = drawingConfig.scaleRatio, isErasing = drawingConfig.isErasing;
    dom_helper_1.transformedDrawImage({
        ctx: ctx.value,
        hiddenCtx: hiddenCtx.value,
        positionRange: positionRange,
        scaleRatio: scaleRatio,
        withBorder: isInResultBoard(isErasing),
    });
}
function isInResultBoard(isErasing) {
    return isErasing !== undefined;
}
exports.isInResultBoard = isInResultBoard;
function computeCropCropRect(sourceSize, destSize) {
    var sourceRatio = sourceSize.width / sourceSize.height;
    var destRatio = destSize.width / destSize.height;
    var sx;
    var sy;
    var tx;
    var ty;
    if (sourceRatio > destRatio) {
        sx = destSize.height / sourceSize.height;
        sy = destSize.height / sourceSize.height;
        tx = (sourceSize.width * (destSize.height / sourceSize.height) - destSize.width) / 2;
        ty = 0;
    }
    else {
        sx = destSize.width / sourceSize.width;
        sy = destSize.width / sourceSize.width;
        tx = 0;
        ty = (sourceSize.height * (destSize.width / sourceSize.width) - destSize.height) / 2;
    }
    return { sx: sx, sy: sy, tx: tx, ty: ty };
}
exports.computeCropCropRect = computeCropCropRect;
function computeCoverCropRect(sourceSize, destSize) {
    var sourceRatio = sourceSize.width / sourceSize.height;
    var destRatio = destSize.width / destSize.height;
    var sx;
    var sy;
    var tx;
    var ty;
    if (sourceRatio > destRatio) {
        sx = destSize.height / sourceSize.height;
        sy = destSize.height / sourceSize.height;
        tx = (sourceSize.width * (destSize.height / sourceSize.height) - destSize.width) / 2;
        ty = 0;
    }
    else {
        sx = destSize.width / sourceSize.width;
        sy = destSize.width / sourceSize.width;
        tx = 0;
        ty = (sourceSize.height * (destSize.width / sourceSize.width) - destSize.height) / 2;
    }
    return { sx: sx, sy: sy, tx: tx, ty: ty };
}
exports.computeCoverCropRect = computeCoverCropRect;
function renderCoverBgImageInCanvas(bgImage, ctx) {
    var cropRect = computeCoverCropRect(bgImage, ctx.canvas);
    ctx.save();
    ctx.translate(-cropRect.tx, -cropRect.ty);
    ctx.scale(cropRect.sx, cropRect.sy);
    ctx.drawImage(bgImage, 0, 0);
    ctx.restore();
}
exports.renderCoverBgImageInCanvas = renderCoverBgImageInCanvas;
