/**
 *  imageData缩放
 * @param {*} imageData
 * @param {*} width
 * @param {*} height
 */

const resizeImageData = async (imageData, width, height) => {
  const resizeWidth = width;
  const resizeHeight = height;
  const ibm = await createImageBitmap(
    imageData,
    0,
    0,
    imageData.width,
    imageData.height,
    {
      resizeWidth,
      resizeHeight,
    }
  );
  const canvas = document.createElement("canvas");
  canvas.width = resizeWidth;
  canvas.height = resizeHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(ibm, 0, 0, resizeWidth, resizeHeight);
  return ctx.getImageData(0, 0, resizeWidth, resizeHeight);
};

const convertCanvasToImage = (canvas) => {
  var image = new Image();
  image.src = canvas.toDataURL("image/png");
  return image;
};

const downloadFn = (dom, name) => {
  const image = convertCanvasToImage(dom);
  var a = document.createElement("a");
  a.href = image.src;
  a.download = name || "remover-background.png";
  a.click();
  window.URL.revokeObjectURL(image.src);
};

const downloadWithUrl = (url, name) => {
  var a = document.createElement("a");
  a.href = url;
  a.download = name || `${Date.now()}.png`;
  a.click();
  window.URL.revokeObjectURL(url);
}

const createCanvasCtx = (size, id) => {
  let tempCtx = null
  var tempCanvas = null

  if (id) {
    tempCanvas = document.getElementById(id)
    if (!tempCanvas) {
      return
    }

    tempCanvas.width = size?.width || 0;
    tempCanvas.height = size?.height || 0;
    tempCtx = tempCanvas.getContext("2d");
  } else {
    tempCanvas = document.createElement('canvas')
    tempCanvas.width = size?.width || 0
    tempCanvas.height = size?.height || 0
    tempCtx = tempCanvas.getContext('2d')
  }
  return tempCtx
}

const getImageScaleSize = (imgWidth, imgHeight, refSize = 512) => {
  let imgResizeHeight;
  let imgResizeWidth;
  if (
    Math.max(imgHeight, imgWidth) < refSize ||
    Math.min(imgHeight, imgWidth) > refSize
  ) {
    if (imgWidth >= imgHeight) {
      imgResizeHeight = refSize;
      imgResizeWidth = Math.round((imgWidth / imgHeight) * refSize);
    } else {
      imgResizeWidth = refSize;
      imgResizeHeight = Math.round((imgHeight / imgWidth) * refSize);
    }
  } else {
    imgResizeHeight = imgHeight;
    imgResizeWidth = imgWidth;
  }

  imgResizeWidth = imgResizeWidth - (imgResizeWidth % 32);
  imgResizeHeight = imgResizeHeight - (imgResizeHeight % 32);

  return {
    width: imgResizeWidth,
    height: imgResizeHeight,
  };
};

const clearCanvas = (id) => {
  const element = document?.getElementById(
    id
  );
  if (!element) {
    return
  }
  const ctx = element.getContext("2d");
  if (!ctx) {
    return
  }
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
}

const showImage = (originSize, resizeSize, containerBox) => {
  const originWidth = originSize.width
  const originHeight = originSize.height
  const resizeSizeWidth = resizeSize.width
  const resizeSizeHeight = resizeSize.height
  let imgMultiple = 1
  if (originWidth > resizeSizeWidth || originHeight > resizeSizeHeight) {
    // 长 > 宽
    if (resizeSizeWidth / originWidth > resizeSizeHeight / originHeight) {
      if (resizeSizeWidth / originWidth < 1 && resizeSizeHeight / originHeight < 1) {
        imgMultiple = resizeSizeHeight / originHeight
      } else if (resizeSizeWidth / originWidth >= 1 && resizeSizeHeight / originHeight < 1) {
        imgMultiple = resizeSizeHeight / originHeight
      }

    } else if (resizeSizeWidth / originWidth < resizeSizeHeight / originHeight) {
      // 宽 > 长
      if (resizeSizeWidth / originWidth <= 1 && resizeSizeHeight / originHeight < 1) {
        imgMultiple = resizeSizeWidth / originWidth
      } else if (resizeSizeWidth / originWidth < 1 && resizeSizeHeight / originHeight > 1) {
        imgMultiple = resizeSizeWidth / originWidth
      }
    } else {
      imgMultiple = resizeSizeWidth / originWidth
    }
  } else if (originWidth <= resizeSizeWidth && originHeight <= resizeSizeHeight) {
    // 长 > 宽
    if (resizeSizeWidth / originWidth > resizeSizeHeight / originHeight) {
      imgMultiple = resizeSizeHeight / originHeight
    } else if (resizeSizeWidth / originWidth < resizeSizeHeight / originHeight) {
      imgMultiple = resizeSizeWidth / originWidth
    } else {
      imgMultiple = 1
    }
  }
  const imgWidth = imgMultiple * originWidth
  const imgHeight = imgMultiple * originHeight
  const imgX = (containerBox.width - imgWidth) / 2
  const imgY = (containerBox.height - imgHeight) / 2
  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight
  }

}

const resizeImage = (imageData, ratio) => {
  const originWidth = imageData.width;
  const originHeight = imageData.height;
  const ratioList = ratio.split(':');
  const ratioNumber = Number(ratioList[0]) / Number(ratioList[1]);
  // 裁剪原则: 以中心为基准点，修改比例小的一侧

  if (ratioNumber >= 1 &&
    (originWidth * Number(ratioList[1])) / Number(ratioList[0]) <
    originHeight
  ) {
    return {
      width: originWidth,
      height: Math.floor((originWidth * Number(ratioList[1])) / Number(ratioList[0])),
      sx: 0,
      sy: (originHeight - (originWidth * Number(ratioList[1])) / Number(ratioList[0])) / 2,
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: originWidth,
      dHeight: originHeight,
    };
  }

  return {
    width: Math.floor((originHeight * Number(ratioList[0])) / Number(ratioList[1])),
    height: originHeight,
    sx:
      (originWidth -
        (originHeight * Number(ratioList[0])) / Number(ratioList[1])) /
      2,
    sy: 0,
    sWidth: originWidth,
    sHeight: originHeight,
    dx: 0,
    dy: 0,
    dWidth: originWidth,
    dHeight: originHeight,
  };
};


const resizeImageWidthOrHeight = (imageData, imageSize) => {
  const originWidth = imageData.width;
  const originHeight = imageData.height;
  const resultWidth = imageSize.width;
  const resultHeight = imageSize.height;
  if (resultWidth <= originWidth && resultHeight <= originHeight) {
    return {
      width: resultWidth,
      height: resultHeight,
      sx: (originWidth - resultWidth) / 2,
      sy: (originHeight - resultHeight) / 2,
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: originWidth,
      dHeight: originHeight,
    };
  }
  if (resultWidth < originWidth && resultHeight > originHeight) {
    const resizeWidth = (originWidth * resultHeight) / originHeight;
    return {
      width: resultWidth,
      height: resultHeight,
      sx: Math.abs(resizeWidth - originWidth) / (2 * resizeWidth / originWidth),
      sy: 0,
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: resizeWidth,
      dHeight: resultHeight,
    };
  }
  if (resultWidth > originWidth && resultHeight < originHeight) {
    const resizeHeight = (originHeight * resultWidth) / originWidth;
    return {
      width: resultWidth,
      height: resultHeight,
      sx: 0,
      sy: Math.abs(resizeHeight - originHeight) / (2 * resizeHeight / originHeight),
      sWidth: originWidth,
      sHeight: originHeight,
      dx: 0,
      dy: 0,
      dWidth: resultWidth,
      dHeight: resizeHeight,
    };
  }
  if (resultWidth > originWidth && resultHeight > originHeight) {
    const ratioWidth = resultWidth / originWidth;
    const ratioHeight = resultHeight / originHeight;
    if (ratioWidth > ratioHeight) {
      const resizeHeight = (originHeight * resultWidth) / originWidth;
      return {
        width: resultWidth,
        height: resultHeight,
        sx: 0,
        sy:
          Math.abs(resizeHeight - resizeHeight) /
          ((2 * resizeHeight) / originHeight),
        sWidth: originWidth,
        sHeight: originHeight,
        dx: 0,
        dy: 0,
        dWidth: resultWidth,
        dHeight: resizeHeight,
      };
    }

    if (ratioWidth < ratioHeight) {
      const resizeWidth = (originWidth * resultHeight) / originHeight;
      return {
        width: resultWidth,
        height: resultHeight,
        sx:
          Math.abs(resizeWidth - resultWidth) /
          ((2 * resizeWidth) / originWidth),
        sy: 0,
        sWidth: originWidth,
        sHeight: originHeight,
        dx: 0,
        dy: 0,
        dWidth: resizeWidth,
        dHeight: resultHeight,
      };
    }
  }
};


export default {
  resizeImageData,
  downloadFn,
  downloadWithUrl,
  createCanvasCtx,
  getImageScaleSize,
  clearCanvas,
  showImage,
  resizeImage,
  resizeImageWidthOrHeight
}
